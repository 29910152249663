import { http } from "./axiosUtils";
import { getPictureUrl } from "../utils/assetsUtils";

export const syncCache = async (courseId, courseLessons) => {
  if (process.env.NODE_ENV === "production") {
    const courseImages = courseLessons.reduce(lessonsImageReducer, []);

    const cacheStorage = await caches.open(`courses-${courseId}-image-cache`);

    const { imagesToFetch, itemsToDeleteInCache } = uncommonItems(
      await cacheStorage.keys(),
      courseImages.map((name) => getPictureUrl(courseId, name))
    );

    await Promise.allSettled([
      ...itemsToDeleteInCache.map((item) => cacheStorage.delete(item)),
      ...imagesToFetch.map((image) => http.get(image)),
    ]);
  }
};

function lessonsImageReducer(acc, lessons) {
  const imageRelatedContents = lessons.content.filter(
    ({ type }) =>
      type === "lesson-image" ||
      type === "lesson-columns" ||
      type === "lesson-slides"
  );
  const images = imageRelatedContents.reduce((accImages, { type, content }) => {
    if (type === "lesson-image") {
      return accImages.concat([content]);
    }
    return accImages.concat(lessonsImageReducer(accImages, { content }));
  }, []);
  return acc.concat(images);
}

function uncommonItems(cacheItems, courseImages) {
  const cacheUrls = new Set(cacheItems.map((item) => item.url));
  const imagesUrls = new Set(courseImages);

  const imagesToFetch = Array.from(imagesUrls).filter(
    (image) => !cacheUrls.has(image)
  );
  const itemsToDeleteInCache = cacheItems.filter(
    (item) => !imagesUrls.has(item.url)
  );
  return { imagesToFetch, itemsToDeleteInCache };
}
