<template>
  <v-container class="course" :class="`module-${moduleId}`">
    <v-row justify="center">
      <v-col class="px-0 header">
        <div class="cover mb-5">
          <p class="introduction" v-html="lesson.cover" />
          <p class="lesson-name">
            {{ lesson.title }}
          </p>
        </div>
        <lesson-assets
          class="lesson-assets ma-2"
          v-if="$vuetify.breakpoint.mdAndDown"
        />
      </v-col>
    </v-row>
    <v-row justify="center" class="px-2 px-md-8">
      <v-col cols="12" class="px-0 content">
        <lesson-dynamic-component
          :component="component"
          :course-id="courseId"
          v-for="(component, i) in lesson.content"
          :key="i"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LessonDynamicComponent from "@/components/course/lesson/components/LessonDynamicComponent";
import CourseMixin from "@/mixins/CourseMixin";
import LessonAssets from "@/components/course/LessonAssets";

export default {
  name: "LessonContent",
  mixins: [CourseMixin],
  components: { LessonAssets, LessonDynamicComponent },
};
</script>
