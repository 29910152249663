<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        :to="
          isStarted && !notEnrolledInFreeCourse
            ? `/app/course/${course.id}`
            : undefined
        "
        outlined
        class="pa-0"
        style="max-width: 450px"
      >
        <ribbon v-if="notEnrolledInFreeCourse" />
        <expiration-box :starts="course.starts" :finishes="course.finish" />

        <v-card-text class="pa-0">
          <v-img :src="pictureUrl" />
        </v-card-text>
        <v-fade-transition>
          <v-overlay
            v-if="hover"
            absolute
            color="brown"
            class="pa-4"
            opacity="0.85"
            z-index="3"
          >
            <v-container>
              <v-row>
                <v-col cols="12">
                  <p class="course-description">
                    {{ course.description }}
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    @click="enroll"
                    class="ma-2"
                    outlined
                    x-large
                    block
                    color="white"
                    v-if="notEnrolledInFreeCourse"
                  >
                    ¡Apúntate!
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-divider color="white" v-if="!isStarted" />
                </v-col>
                <v-col cols="12">
                  <p class="course-description" v-if="!isStarted">
                    ¡Empezamos el {{ course.starts | dateFormat }}!
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-overlay>
        </v-fade-transition>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
import { authActions, authGetters } from "@/store/auth";
import ExpirationBox from "@/components/course/ExpirationBox";
import { courseStarted } from "@/utils/timeUtils";
import { getCoversUrl, getPictureUrl } from "@/utils/assetsUtils";
import Ribbon from "@/components/course/Ribbon";
import { enrollFreeCourse } from "@/services/courses";

export default {
  name: "CourseCard",
  components: { Ribbon, ExpirationBox },
  props: {
    course: Object,
  },
  data() {
    return {
      assetsDomain: process.env.VUE_APP_ASSETS_DOMAIN,
    };
  },
  computed: {
    isOwnedByUser() {
      return !!this.$store.getters[authGetters.getCourseClaim](this.course.id);
    },
    isStarted() {
      return !this.course.starts || courseStarted(this.course.starts);
    },
    pictureUrl() {
      return getPictureUrl(this.course.id, this.course.cardImage);
    },
    coverUrl() {
      return getCoversUrl(this.course.id, this.course.cover);
    },
    notEnrolledInFreeCourse() {
      return this.course.free && !this.course.draft && !this.isOwnedByUser;
    },
  },
  methods: {
    async enroll() {
      const checkPermissions = async (times = 5) => {
        if (this.isOwnedByUser) {
          return true;
        }
        if (times === 0) {
          return false;
        }
        await this.$store.dispatch(authActions.cognitoData);
        return await defer(() => checkPermissions(times - 1));
      };

      this.$loading.loading();
      try {
        await enrollFreeCourse(this.course.id);
        const result = await checkPermissions();
        if (!result) {
          this.$snackbar.error(
            "Estamos tardando mas de la cuenta. Inténtalo de nuevo en unos minutos. Si el problema persiste, ponte en contacto con nosotros"
          );
        } else if (this.isStarted) {
          return this.$router.push(`/app/course/${this.course.id}`);
        } else {
          this.$snackbar.info(
            `Te has inscrito con éxito en el curso '${
              this.course.title
            }'. ¡Te esperamos el ${this.$options.filters.dateFormat(
              this.course.starts
            )}!`
          );
        }
      } finally {
        this.$loading.stopLoading();
      }
    },
  },
  filters: {
    dateFormat(value) {
      const options = { month: "long", day: "numeric" };
      return new Date(value).toLocaleDateString("es-ES", options);
    },
  },
};

function defer(action) {
  return new Promise((resolve) => {
    setTimeout(() => action().then(resolve), 1000);
  });
}
</script>

<style scoped lang="sass">
.course-description
  font-size: 22px
  text-align: center
  font-family: Gupter, sans-serif
  color: var(--v-black-base)
</style>
