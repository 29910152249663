<template>
  <v-list-item @click.prevent="download">
    <v-list-item-icon>
      <v-icon v-text="assetType" />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title v-text="assetData.title" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { download } from "@/services/assetsDownload";

export default {
  name: "AssetDownload",
  props: {
    assetData: Object,
    assetType: String,
  },
  methods: {
    async download() {
      this.$loading.loading();
      try {
        await download(this.assetData.courseId, this.assetData.source);
      } finally {
        this.$loading.stopLoading();
      }
    },
  },
};
</script>
