<template>
  <asset-download
    :asset-data="assetData"
    asset-type="mdi-book-open-page-variant"
  />
</template>

<script>
import AssetDownload from "@/components/course/lesson/assets/AssetDownload";

export default {
  name: "AssetDocument",
  components: { AssetDownload },
  props: {
    assetData: Object,
  },
};
</script>
