<template>
  <div class="expiration" v-if="courseNotStarted || !!finishes">
    <span v-if="courseNotStarted"> Empieza el {{ starts | dateFormat }} </span>
    <br v-if="courseNotStarted && !!finishes" />
    <span v-if="!!finishes"> Termina el {{ finishes | dateFormat }} </span>
  </div>
</template>

<script>
import { courseStarted } from "../../utils/timeUtils";

export default {
  name: "ExpirationBox",
  props: {
    finishes: String,
    starts: String,
  },
  computed: {
    courseNotStarted() {
      return this.starts && !courseStarted(this.starts);
    },
  },
  filters: {
    dateFormat(date) {
      const options = { month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString("es-ES", options);
    },
  },
};
</script>

<style scoped lang="sass">
@import "../../styles/custom-colors"
.expiration
  position: absolute
  right: 10px
  bottom: 20px
  z-index: 3
  font-size: 16px
  font-weight: bold
  color: $dark-purple
  font-family: Montserrat, sans-serif
  background-color: $yellow
  opacity: 0.8
  padding: 10px
</style>
