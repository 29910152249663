<template>
  <v-container fluid class="pt-0">
    <v-row justify="center">
      <v-col
        cols="12"
        sm="10"
        lg="8"
        style="position: relative; bottom: 40px"
        class="pa-0"
      >
        <courses-gallery :courses="courses" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { coursesActions, coursesGetters } from "@/store/courses";
import CoursesGallery from "@/components/course/CoursesGallery";
import { userGetters } from "@/store/user";

export default {
  components: { CoursesGallery },
  created() {
    if (this.courses.length === 0) {
      this.$loading.loading();
    }
    return this.$store
      .dispatch(coursesActions.list)
      .finally(() => this.$loading.stopLoading());
  },
  computed: {
    user() {
      const user = this.$store.getters[userGetters.getUser];
      return user.userName || user.email;
    },
    courses() {
      return this.$store.getters[coursesGetters.list];
    },
  },
};
</script>

<style scoped lang="sass"></style>
