<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-navigation-drawer
    app
    clipped
    color="#FAECDD"
    v-model="drawer"
    :width="isSmallSize ? '85%' : 400"
    :permanent="!isSmallSize"
  >
    <v-list>
      <v-list-group
        no-action
        sub-group
        v-for="itemModuleId in course.modulesIds || []"
        :key="itemModuleId"
        :value="moduleId === itemModuleId"
      >
        <template v-slot:activator>
          <v-list-item-title>
            {{
              modules[itemModuleId] && modules[itemModuleId].title.toUpperCase()
            }}
          </v-list-item-title>
        </template>

        <v-list-item
          v-for="itemLessonId in modules[itemModuleId].lessonsIds || []"
          :key="itemLessonId"
          :to="`/app/course/${courseId}/${itemModuleId}/${itemLessonId}`"
          style="padding-left: 15px !important"
        >
          <v-list-item-icon>
            <v-icon color="blue" v-if="lessonIsPassed(itemLessonId)">
              mdi-check
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ lessons[itemLessonId] && lessons[itemLessonId].title }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import CourseMixin from "../../mixins/CourseMixin";

export default {
  name: "CourseIndexDrawer",
  mixins: [CourseMixin],
  props: {
    isSmallSize: Boolean,
  },
  watch: {
    $route() {
      this.setCourseCoordinates();
    },
  },
  computed: {
    drawer: {
      get() {
        return !this.isSmallSize || this.$store.state.isDrawerVisible;
      },
      set(val) {
        this.$store.commit("showDrawer", val);
      },
    },
  },
  methods: {
    lessonIsPassed(itemLessonId) {
      const lessonsPassed = this.userProgress.lessonsPassed;
      return lessonsPassed && lessonsPassed.includes(itemLessonId);
    },
  },
};
</script>

<style lang="sass">
.v-navigation-drawer
  .v-list-group__header
    padding-left: 5px !important

  a.v-list-item.v-list-item--link
    padding-left: 55px !important

  .v-list-group__items .v-list-item
    font-family: 'Gupter', serif
    font-size: 20px

    .v-list-item__title
      font-family: 'Gupter', serif
      color: var(--v-blue-base)
      line-height: 1

  .v-list-item
    &.v-list-item--active
      color: var(--v-red-base)

    &:hover
      color: var(--v-blue-base)

  .v-list-item__title
    font-family: 'Josefin Sans', sans-serif
    color: var(--v-black-base)
    line-height: 1
</style>
