export function courseStarted(start) {
  const startDate = timeZoneCorrectedDate(start);
  return startDate <= getToday();
}

export function courseFinished(finish) {
  const finishDate = timeZoneCorrectedDate(finish);
  return finishDate < getToday();
}

function getToday() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}

function timeZoneCorrectedDate(dateString) {
  const date = new Date(dateString);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
}
