<template>
  <v-container class="audio">
    <v-row justify="center" class="audio-desc">
      <h3>{{ componentData.title }}</h3>
    </v-row>
    <v-row justify="center" class="audio-controllers">
      <audio :title="componentData.title" preload="auto" controls>
        <source :src="getAudioUrl()" type="audio/mpeg" />
      </audio>
    </v-row>
  </v-container>
</template>

<script>
import { getAssetsUrl } from "@/utils/assetsUtils";

export default {
  name: "LessonAudio",
  props: {
    componentData: Object,
    courseId: String,
  },
  methods: {
    getAudioUrl() {
      return getAssetsUrl(this.courseId, this.componentData.content + ".mp3");
    },
  },
};
</script>

<style lang="sass">
.audio-controllers
  margin-top: 10px
.audio-desc
  margin-bottom: 10px
</style>
