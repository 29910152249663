<template>
  <v-container :class="`ma-0 pa-0 course-${courseId}`">
    <v-row>
      <v-container class="py-0" fluid>
        <course-index-drawer :is-small-size="$vuetify.breakpoint.smAndDown" />
        <v-row justify="space-around">
          <v-col cols="12" lg="8" class="pt-0">
            <v-img class="course-title" :src="coverUrl" />
            <transition name="fade" mode="out-in">
              <router-view class="view" :key="$route.fullPath" />
            </transition>
            <course-navigator class="navigator my-4" />
          </v-col>
          <v-col lg="4" class="hidden-md-and-down" style="padding-top: 150px">
            <lesson-assets style="position: sticky; top: 20px" />
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import CourseIndexDrawer from "@/components/course/CourseIndexDrawer";
import CourseNavigator from "@/components/course/CourseNavigator";
import CourseMixin from "@/mixins/CourseMixin";
import LessonAssets from "@/components/course/LessonAssets";
import { coursesActions } from "@/store/courses";
import { userActions } from "@/store/user";
import { syncCache } from "@/services/cache";
import { getCoversUrl } from "@/utils/assetsUtils";

export default {
  components: { LessonAssets, CourseNavigator, CourseIndexDrawer },
  mixins: [CourseMixin],
  created() {
    this.initializeCourse();
    this.routeIfPathProvided(this.reroutePath);
    if (isEmpty(this.course)) {
      return this.$loading.loading();
    }
  },
  data() {
    return {
      assetsDomain: process.env.VUE_APP_ASSETS_DOMAIN,
    };
  },
  watch: {
    $route() {
      this.setCourseCoordinates();
    },
    reroutePath(path) {
      this.routeIfPathProvided(path);
    },
    course(val, oldVal) {
      if (isEmpty(val)) {
        return this.$loading.loading();
      }
      if (isEmpty(oldVal)) {
        return this.$loading.stopLoading();
      }
    },
  },
  computed: {
    reroutePath() {
      if (!this.moduleId && !this.lessonId && this.course.modulesIds) {
        const lastCompleteLessonId = this.userProgress.lastCompleted;
        const lastCompleteModuleId = this.findLessonModule(
          lastCompleteLessonId
        );
        const firstModuleId = lastCompleteModuleId || this.course.modulesIds[0];
        const firstLessonId =
          lastCompleteLessonId || this.modules[firstModuleId].lessonsIds[0];
        return `/app/course/${this.courseId}/${firstModuleId}/${firstLessonId}`;
      }
      return null;
    },
    coverUrl() {
      return getCoversUrl(this.courseId, this.course.cover);
    },
  },
  methods: {
    routeIfPathProvided(path) {
      if (path) {
        this.$router.push({ path });
      }
    },
    initializeCourse() {
      const payload = {
        courseId: this.courseId,
        lastModified: this.course.lastModified,
      };
      const getCourse = this.$store
        .dispatch(coursesActions.get, payload)
        .then(() => syncCache(this.courseId, Object.values(this.lessons)))
        .catch((e) => {
          if (e.status === 404) {
            this.$snackbar.error("El curso solicitado no existe");
            return this.$router.push("/");
          }
          if (e.status === 403) {
            this.$snackbar.error("El curso no está disponible: " + e.data);
            return this.$router.push("/");
          }
          if (isEmpty(this.course)) {
            this.$snackbar.error(
              "No se pudo acceder al curso por un error desconocido. Inténtalo de nuevo: " +
                e.data
            );
          }
          this.$sentry.capture(e);
        });

      const getProgress = isEmpty(this.userProgress)
        ? this.$store.dispatch(userActions.getProgress, this.courseId)
        : Promise.resolve();

      return Promise.all([getCourse, getProgress]);
    },
    findLessonModule(lessonId) {
      if (!lessonId) return undefined;

      for (let [moduleId, module] of Object.entries(this.modules)) {
        if (module.lessonsIds.indexOf(lessonId) !== -1) {
          return moduleId;
        }
      }
    },
  },
};
const isEmpty = (object) => !object || Object.keys(object).length === 0;
</script>

<style lang="sass">
@import "../../styles/courses"
@import "../../styles/custom-colors"

//All classes starting by "course-"
div[class^="course-"], div[class*=" course-"]
  width: 100%
  max-width: 100%
  background-color: white

.fade-enter-active, .fade-leave-active
  transition-duration: 0.1s
  transition-property: opacity
  transition-timing-function: ease

.fade-enter, .fade-leave-active
  opacity: 0
</style>
