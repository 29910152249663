<template>
  <v-container>
    <v-row class="pa-5" style="text-align: center">
      <v-col cols="12" class="course-description">
        {{ course.description.toUpperCase() }}
      </v-col>
      <v-col cols="12">
        <v-divider color="#80659d" />
      </v-col>
      <v-col cols="12">
        <v-list class="action-list">
          <div @click="tellMe" v-if="course.infoExternalLink">
            <available-courses-menu-item
              text="Cuéntame Más"
              icon="mdi-information-outline"
            />
          </div>

          <div @click="courseCheckin" v-if="course.free">
            <available-courses-menu-item
              text="¡Me Apunto!"
              icon="mdi-school"
              :loading="loadingEnrollMe"
              highlight
            />
          </div>

          <available-courses-menu-item
            v-if="!course.free"
            text="¡Quiero Probarlo!"
            icon="mdi-school"
            highlight
          />

          <available-courses-menu-item
            v-if="!course.free && course.buyExternalLink"
            text="¡Me lo llevo!"
            icon="mdi-credit-card-outline"
          />
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { coursesActions } from "@/store/courses";
import { authActions, authGetters } from "@/store/auth";
import AvailableCoursesMenuItem from "@/components/course/AvailableCoursesMenuItem";
import { courseStarted } from "@/utils/timeUtils";

export default {
  name: "AvailableCoursesMenu",
  components: { AvailableCoursesMenuItem },
  props: {
    course: Object,
  },
  data() {
    return {
      loadingEnrollMe: false,
    };
  },
  computed: {
    isOwnedByUser() {
      return !!this.$store.getters[authGetters.getCourseClaim](this.course.id);
    },
  },
  methods: {
    async courseCheckin() {
      try {
        this.loadingEnrollMe = true;

        await this.$store.dispatch(
          coursesActions.enrollFreeCourse,
          this.course.id
        );
        await defer(() => this.$store.dispatch(authActions.cognitoData));

        if (this.isOwnedByUser && courseStarted(this.course.starts)) {
          await this.$router.push(`/app/course/${this.course.id}`);
        }

        this.$snackbar.info(
          `Te has inscrito con éxito en el curso: '${this.course.title}'. Si no puedes ver el curso en la sección de tus cursos refresca la aplicación`
        );
      } catch (e) {
        if (e.status === 401) {
          this.$snackbar.error(
            "No tienes permiso para acceder a este curso: " + e.data
          );
        } else if (e.status === 404) {
          this.$snackbar.error(
            "El curso que buscas ya/todabía no está disponible"
          );
        } else {
          this.$snackbar.error(
            "Un problema desconocido evitó que se te pudiera inscribir en el curso. Inténtalo mas tarde"
          );
        }
      } finally {
        this.loadingEnrollMe = false;
      }
    },
    tellMe() {
      window.open(this.course.infoExternalLink, "_blank");
    },
    buy() {
      window.open(this.course.buyExternalLink, "_blank");
    },
  },
};

function defer(action) {
  return new Promise((resolve) => {
    setTimeout(() => action().then(resolve), 3000);
  });
}
</script>

<style scoped lang="sass">
@import "../../styles/custom-colors"

.course-description
  font-size: 20px
  text-align: center
  font-family: Montserrat, sans-serif
  font-weight: 500
  color: $purple

.action-list
  background-color: transparent

  .action
    margin-top: 10px
    margin-bottom: 10px
    border-radius: 20px
    border: thin solid $purple

    &:hover
      cursor: pointer
      background-color: $yellow
      opacity: 0.5

    .action-title
      color: $purple
      font-weight: 700
      font-size: 20px

    .v-icon
      color: $light-purple

    &.highlight
      background-color: $orange
      border-style: none

      &:hover
        opacity: 0.75

      .action-title
        color: white

      .v-icon
        color: white
</style>
