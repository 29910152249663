<template>
  <v-container class="video">
    <v-row justify="center">
      <iframe
        :src="`https://player.vimeo.com/video/${componentData.content}`"
        :width="videoWidth"
        :height="videoHeight"
        frameborder="0"
        allow="fullscreen"
        allowfullscreen
      />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Video",
  props: {
    componentData: Object,
    courseId: String,
  },
  data() {
    return {
      videoWidth: 640,
      videoHeight: 360,
    };
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize);
    }
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.videoWidth = Math.min(window.innerWidth, 640);
      this.videoHeight = Math.min(this.videoWidth * 0.6, 360);
    },
  },
};
</script>

<style scoped></style>
