import { coursesGetters } from "../store/courses";
import { userGetters } from "../store/user";

export default {
  data() {
    return {
      courseId: this.$route.params.courseId,
      moduleId: this.$route.params.moduleId,
      lessonId: this.$route.params.lessonId,
    };
  },
  watch: {
    $route() {
      this.setCourseCoordinates();
    },
  },
  computed: {
    userProgress() {
      if (this.courseId) {
        return this.$store.getters[userGetters.progress](this.courseId) || {};
      }
      return {};
    },
    course() {
      if (this.courseId) {
        return this.$store.getters[coursesGetters.courses][this.courseId] || {};
      }
      return {};
    },
    module() {
      if (this.course && this.moduleId) {
        return this.course.modules[this.moduleId] || {};
      }
      return {};
    },
    lesson() {
      if (this.course && this.lessonId) {
        return this.course.lessons[this.lessonId] || {};
      }
      return {};
    },
    modules() {
      return this.course.modules || {};
    },
    lessons() {
      return this.course.lessons || {};
    },
    previousLessonUrl() {
      if (this.previousLessonId) {
        return {
          url: `/app/course/${this.courseId}/${this.moduleId}/${this.previousLessonId}`,
          description: this.lessons[this.previousLessonId].title,
        };
      }
      if (this.previousModuleId) {
        const previousModuleLessonsIds = this.modules[this.previousModuleId]
          .lessonsIds;
        const previousModuleLessonId =
          previousModuleLessonsIds[previousModuleLessonsIds.length - 1];
        return {
          url: `/app/course/${this.courseId}/${this.previousModuleId}/${previousModuleLessonId}`,
          description: this.modules[this.previousModuleId].title,
        };
      }
    },
    nextLessonUrl() {
      if (this.nextLessonId) {
        return {
          url: `/app/course/${this.courseId}/${this.moduleId}/${this.nextLessonId}`,
          description: this.lessons[this.nextLessonId].title,
        };
      }
      if (this.nextModuleId) {
        const nextModuleLessonsId = this.modules[this.nextModuleId]
          .lessonsIds[0];
        return {
          url: `/app/course/${this.courseId}/${this.nextModuleId}/${nextModuleLessonsId}`,
          description: this.modules[this.nextModuleId].title,
        };
      }
    },
    nextLessonId() {
      const lessonsIds = this.module.lessonsIds;
      if (!lessonsIds) return;

      const index = lessonsIds.indexOf(this.lessonId);

      if (!(index > -1 && index < lessonsIds.length - 1)) return;

      return lessonsIds[index + 1];
    },
    nextModuleId() {
      const modulesIds = this.course.modulesIds;
      if (!modulesIds) return;

      const index = modulesIds.indexOf(this.moduleId);

      if (!(index > -1 && index < modulesIds.length - 1)) return;
      return modulesIds[index + 1];
    },
    previousLessonId() {
      const lessonsKeys = this.module.lessonsIds;
      if (!lessonsKeys) return;

      const index = lessonsKeys.indexOf(this.lessonId);
      if (!(index > 0 && index < lessonsKeys.length)) return;

      return lessonsKeys[index - 1];
    },
    previousModuleId() {
      const modulesKeys = this.course.modulesIds;
      if (!modulesKeys) return;

      const index = modulesKeys.indexOf(this.moduleId);
      if (!(index > 0 && index < modulesKeys.length)) return;

      return modulesKeys[index - 1];
    },
  },
  methods: {
    setCourseCoordinates() {
      this.courseId = this.$route.params.courseId;
      this.moduleId = this.$route.params.moduleId;
      this.lessonId = this.$route.params.lessonId;
    },
  },
};
