<template>
  <v-container>
    <v-row class="mb-10" v-if="myCourses.length > 0">
      <v-container fluid>
        <v-row align="center">
          <v-col class="text-center my-10 title-gallery">Tus Cursos</v-col>
        </v-row>
        <v-row align="center">
          <v-col md="4" sm="6" :key="course.id" v-for="course in myCourses">
            <course-card :course="course" />
          </v-col>
        </v-row>
      </v-container>
    </v-row>

    <v-row v-if="availableCourses.length > 0" style="background-color: #b3a3c4">
      <v-container>
        <v-row>
          <v-col xl="4" md="6" class="section-title">
            Cursos Disponibles
          </v-col>
        </v-row>
        <v-row>
          <v-col
            xl="4"
            md="6"
            :key="course.id"
            v-for="course in availableCourses"
          >
            <extended-course-card :course="course" />
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import CourseCard from "./CourseCard";
import { authGetters } from "../../store/auth";
import ExtendedCourseCard from "./ExtendedCourseCard";
import { courseFinished } from "../../utils/timeUtils";

export default {
  name: "CoursesGallery",
  props: {
    courses: Array,
  },
  components: { ExtendedCourseCard, CourseCard },
  computed: {
    unfinishedCourses() {
      return this.courses.filter(this.isNotFinished);
    },
    myCourses() {
      return this.unfinishedCourses.filter(this.userIsAllowed);
    },
    availableCourses() {
      return this.unfinishedCourses
        .filter((c) => !this.userIsAllowed(c))
        .filter((c) => !c.draft);
    },
  },
  methods: {
    userIsAllowed(course) {
      return (
        course.free ||
        !!this.$store.getters[authGetters.getCourseClaim](course.id)
      );
    },
    isNotFinished(course) {
      return !course.finish || !courseFinished(course.finish);
    },
  },
};
</script>

<style scoped lang="sass">

.title-gallery
  font-size: calc(12px + 1.2vh)
  color: var(--v-blue-base)
  text-align: center
  text-transform: none
  font-family: Gurajada, sans-serif
</style>
