<template>
  <v-carousel class="slides" :height="sliderHeight" :hide-delimiters="true">
    <v-carousel-item v-for="(slide, i) in componentData.content" :key="i">
      <v-container class="pa-0">
        <v-row align="center" justify="center">
          <v-col>
            <lesson-dynamic-component
              :component="slide"
              :course-id="courseId"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import LessonDynamicComponent from "./LessonDynamicComponent";

export default {
  name: "LessonSlides",
  components: { LessonDynamicComponent },
  props: {
    componentData: Object,
    courseId: String,
  },
  data() {
    return {
      sliderHeight: 640,
    };
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize);
    }
  },
  created() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.sliderHeight = Math.min(window.innerWidth, 640);
    },
  },
};
</script>
