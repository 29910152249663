<template>
  <keep-alive>
    <component
      :is="component.type"
      :componentData="component"
      :course-id="courseId"
    />
  </keep-alive>
</template>

<script>
import LessonImage from "@/components/course/lesson/components/LessonImage";
import LessonText from "@/components/course/lesson/components/LessonText";
import LessonVideo from "@/components/course/lesson/components/LessonVideo";
import LessonAudio from "@/components/course/lesson/components/LessonAudio";

export default {
  name: "LessonDynamicComponent",
  props: {
    component: Object,
    courseId: String,
  },
  components: {
    LessonVideo,
    LessonText,
    LessonImage,
    LessonAudio,
  },
  beforeCreate() {
    //To not get a circular reference error
    this.$options.components.LessonColumns = require("./LessonColumns.vue").default;
    this.$options.components.LessonSlides = require("./LessonSlides.vue").default;
    this.$options.components.LessonAccordion = require("./LessonAccordion.vue").default;
  },
};
</script>
