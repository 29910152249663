<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="(panel, i) in componentData.content" :key="i">
      <v-expansion-panel-header>
        <span v-html="panel.title" />
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <lesson-dynamic-component
          :component="panel.content"
          :course-id="courseId"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import LessonDynamicComponent from "./LessonDynamicComponent";

export default {
  name: "LessonAccordion",
  components: { LessonDynamicComponent },
  props: {
    componentData: Object,
    courseId: String,
  },
};
</script>

<style scoped lang="sass">
.v-expansion-panel:not(.v-expansion-panel--active)
  margin-bottom: 16px
</style>
