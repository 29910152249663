<template>
  <span v-html="componentData.content" class="text" />
</template>

<script>
export default {
  name: "LessonText",
  props: {
    componentData: Object,
    courseId: String,
  },
};
</script>
