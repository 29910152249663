<template>
  <v-container class="navigator">
    <v-row align-center justify-space-between>
      <v-col md-5 xs-5>
        <v-tooltip top v-if="previousLessonUrl">
          <template v-slot:activator="{ on }">
            <v-btn
              text
              :to="previousLessonUrl.url"
              v-on="on"
              color="#DE7851"
              class="previous-button"
            >
              <v-icon>mdi-chevron-left</v-icon>
              <span class="hidden-sm-and-down"> ANTERIOR </span>
            </v-btn>
          </template>
          <span>{{ previousLessonUrl.description }}</span>
        </v-tooltip>
      </v-col>

      <v-col md-2 xs-2 style="text-align: center">
        <v-btn
          class="passed-button"
          @click="markAsRead"
          :disabled="lessonIsPassed"
          color="#DE7851"
          :loading="loading"
        >
          <span style="color: white">Completado</span>
        </v-btn>
      </v-col>

      <v-col md-5 xs-5 style="text-align: right">
        <v-tooltip top v-if="nextLessonUrl">
          <template v-slot:activator="{ on }">
            <v-btn
              text
              :to="nextLessonUrl.url"
              v-on="on"
              color="#DE7851"
              class="next-button"
            >
              <span class="hidden-sm-and-down"> SIGUIENTE </span>
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <span>{{ nextLessonUrl.description }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CourseMixin from "../../mixins/CourseMixin";
import { userActions } from "../../store/user";

export default {
  name: "CourseNavigator",
  mixins: [CourseMixin],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    lessonIsPassed() {
      const lessonsPassed = this.userProgress.lessonsPassed;
      return lessonsPassed && lessonsPassed.includes(this.lessonId);
    },
  },
  methods: {
    async markAsRead() {
      try {
        this.loading = true;
        await this.$store.dispatch(userActions.updateProgress, {
          courseId: this.courseId,
          lessonId: this.lessonId,
        });
        if (this.nextLessonUrl && this.nextLessonUrl.url) {
          return this.$router.push(this.nextLessonUrl.url);
        }
      } catch (e) {
        this.$snackbar.error("No se pudo guardar el progresso: " + e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="sass" scoped>

$Negro: #3C3533
$Blanco: #FFF
$Rojo: #DE7851
$Marron: #E1A984
$Crema: #FAECDD
$CremaLight: #FCF2E4
$Azul: #183B48
</style>
