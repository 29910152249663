<template>
  <v-list-item class="action" :class="{ highlight: highlight }" v-ripple>
    <v-list-item-icon>
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title class="action-title">
        <span v-if="!loading">{{ text }}</span>
        <v-progress-circular v-if="loading" indeterminate color="#80659d" />
      </v-list-item-title>
    </v-list-item-content>
    <v-list-item-action>
      <v-icon>mdi-chevron-right</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: "AvailableCoursesMenuItem",
  props: {
    icon: String,
    text: String,
    highlight: Boolean,
    loading: Boolean,
  },
};
</script>

<style scoped lang="sass">
@import "../../styles/custom-colors"
.action
  margin-top: 10px
  margin-bottom: 10px
  border-radius: 20px
  border: thin solid $purple

  &:hover
    cursor: pointer
    background-color: $yellow
    opacity: 0.5

  .action-title
    color: $purple
    font-weight: 700
    font-size: 20px

  .v-icon
    color: $light-purple

  &.highlight
    background-color: $orange
    border-style: none

    &:hover
      opacity: 0.75

    .action-title
      color: white

    .v-icon
      color: white
</style>
