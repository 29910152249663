<template>
  <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.smAndDown">
    <template v-slot:activator="{ on }">
      <v-list-item>
        <v-list-item-icon>
          <v-icon v-text="'mdi-file-document-box-check-outline'" />
        </v-list-item-icon>
        <v-list-item-content v-on="on">
          <v-list-item-title v-text="assetData.title" />
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-toolbar>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ assetData.title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container fluid>
          <v-item-group v-model="selectedTile" multiple>
            <v-row justify="center">
              <v-col>
                <h2
                  v-if="assetData.description"
                  v-html="assetData.description"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col
                cols="4"
                sm="3"
                lg="2"
                v-for="(tile, i) in assetData.tiles"
                :key="i"
              >
                <v-item
                  v-slot:default="{ active, toggle }"
                  style="cursor: pointer"
                >
                  <v-img
                    :src="getPictureUrl(assetData.courseId, tile.resource)"
                    class="image"
                    :contain="true"
                    :gradient="
                      active
                        ? 'to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)'
                        : ''
                    "
                    @click="toggle"
                  >
                    <v-btn icon>
                      <v-icon v-if="active"> mdi-check-decagram</v-icon>
                    </v-btn>
                  </v-img>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { userGetters, userMutations } from "../../../../store/user";
import { getPictureUrl } from "../../../../utils/assetsUtils";

export default {
  name: "AssetBingo",
  props: {
    assetData: Object,
  },
  data() {
    return {
      dialog: false,
      assetsDomain: process.env.VUE_APP_ASSETS_DOMAIN,
    };
  },
  computed: {
    selectedTile: {
      get: function () {
        const lessonProgress =
          this.$store.getters[userGetters.exerciseProgress](
            this.assetData.lessonId
          ) || [];
        const selectedTilesIds = lessonProgress[this.assetData.id] || [];
        return this.assetData.tiles
          .map((tile, index) =>
            selectedTilesIds.indexOf(tile.id) !== -1 ? index : -1
          )
          .filter((index) => index !== -1);
      },
      set: function (selected) {
        const selectedIds = selected.map(
          (selectionIndex) => this.assetData.tiles[selectionIndex].id
        );
        const payload = {
          lessonId: this.assetData.lessonId,
          exerciseData: { [this.assetData.id]: selectedIds },
        };
        this.$store.commit(userMutations.setExerciseProgress, payload);
      },
    },
  },
  methods: {
    getPictureUrl(courseId, image) {
      return getPictureUrl(courseId, image);
    },
  },
};
</script>
