<template>
  <div class="ribbon ribbon-bottom-left">
    <span class="ribbon-text">gratis</span>
  </div>
</template>

<script>
export default {
  name: "Ribbon",
};
</script>

<style scoped lang="sass">
@import "../../styles/custom-colors"
.ribbon
  width: 150px
  height: 150px
  overflow: hidden
  position: absolute
  z-index: 4

.ribbon::before, .ribbon::after
  position: absolute
  z-index: -1
  content: ''
  display: block
  border: 5px solid $dark-purple

.ribbon span
  position: absolute
  display: block
  width: 225px
  padding: 15px 0
  background-color: $purple
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1)
  color: #fff
  font: 700 18px/1 'Lato', sans-serif
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2)
  text-transform: uppercase
  text-align: center

.ribbon-bottom-left
  bottom: -10px
  left: -10px

.ribbon-bottom-left::before, .ribbon-bottom-left::after
  border-bottom-color: transparent
  border-left-color: transparent

.ribbon-bottom-left::before
  bottom: 0
  right: 0

.ribbon-bottom-left::after
  top: 0
  left: 0

.ribbon-bottom-left span
  right: -25px
  bottom: 30px
  transform: rotate(45deg)
  font-family: Montserrat, sans-serif
</style>
