var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-file-document-box-check-outline')}})],1),_c('v-list-item-content',_vm._g({},on),[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.assetData.title)}})],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.assetData.title))])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-item-group',{attrs:{"multiple":""},model:{value:(_vm.selectedTile),callback:function ($$v) {_vm.selectedTile=$$v},expression:"selectedTile"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[(_vm.assetData.description)?_c('h2',{domProps:{"innerHTML":_vm._s(_vm.assetData.description)}}):_vm._e()])],1),_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.assetData.tiles),function(tile,i){return _c('v-col',{key:i,attrs:{"cols":"4","sm":"3","lg":"2"}},[_c('v-item',{staticStyle:{"cursor":"pointer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-img',{staticClass:"image",attrs:{"src":_vm.getPictureUrl(_vm.assetData.courseId, tile.resource),"contain":true,"gradient":active
                      ? 'to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)'
                      : ''},on:{"click":toggle}},[_c('v-btn',{attrs:{"icon":""}},[(active)?_c('v-icon',[_vm._v(" mdi-check-decagram")]):_vm._e()],1)],1)]}}],null,true)})],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }