<template>
  <v-container class="mx-0 px-0 columns">
    <v-row justify="space-between" class="mx-0">
      <v-col
        cols="12"
        :sm="12 / componentData.content.length"
        v-for="(column, i) in componentData.content"
        :key="i"
      >
        <lesson-dynamic-component
          :component="column"
          :course-id="courseId"
          class="column"
          style="height: 100%"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LessonDynamicComponent from "./LessonDynamicComponent";

export default {
  name: "LessonColumns",
  components: { LessonDynamicComponent },
  props: {
    componentData: Object,
    courseId: String,
  },
  methods: {
    correctColumnsPadding(columnIndex) {
      if (columnIndex === 0) {
        return "ml-0";
      } else if (columnIndex === this.componentData.content.length - 1) {
        return "mr-0";
      } else {
        return "";
      }
    },
  },
};
</script>
