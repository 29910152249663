<template>
  <div class="image" :class="{ description: !!componentData.description }">
    <v-img :src="pictureUrl" :contain="true" />
    <span
      class="image-desc"
      v-if="componentData.description"
      v-html="componentData.description"
    />
  </div>
</template>
<script>
import { getPictureUrl } from "@/utils/assetsUtils";

export default {
  name: "LessonImage",
  props: {
    componentData: Object,
    courseId: String,
  },
  data() {
    return {
      assetsDomain: process.env.VUE_APP_ASSETS_DOMAIN,
    };
  },
  computed: {
    pictureUrl() {
      return getPictureUrl(this.courseId, this.componentData.content);
    },
  },
};
</script>
