<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card class="pa-0" style="max-width: 450px" outlined>
        <ribbon v-if="course.free" />
        <expiration-box :starts="course.starts" :finishes="course.finish" />
        <v-img :src="coverUrl" class="ma-3" />

        <v-card-text class="pa-0">
          <v-img :src="pictureUrl" />
        </v-card-text>
        <v-fade-transition>
          <v-overlay
            v-if="hover"
            absolute
            color="#fee5b1"
            class="pa-4"
            opacity="0.95"
            z-index="3"
          >
            <available-courses-menu :course="course" />
          </v-overlay>
        </v-fade-transition>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
import Ribbon from "./Ribbon";
import ExpirationBox from "./ExpirationBox";
import AvailableCoursesMenu from "./AvailableCoursesMenu";
import { getCoversUrl, getPictureUrl } from "../../utils/assetsUtils";

export default {
  name: "ExtendedCourseCard",
  components: { AvailableCoursesMenu, ExpirationBox, Ribbon },
  props: {
    course: Object,
  },
  data() {
    return {
      showMenu: false,
      assetsDomain: process.env.VUE_APP_ASSETS_DOMAIN,
    };
  },
  computed: {
    pictureUrl() {
      return getPictureUrl(this.course.id, this.course.cardImage);
    },
    coverUrl() {
      return getCoversUrl(this.course.id, this.course.cover);
    },
  },
};
</script>
