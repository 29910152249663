<template>
  <v-card tile class="assets" v-if="assets.length">
    <v-list dense>
      <v-subheader class="assets-header">RECURSOS</v-subheader>
      <v-list-item-group>
        <component
          :is="asset.type"
          :assetData="{ ...asset.data, lessonId, courseId }"
          v-for="(asset, i) in assets"
          :key="i"
        />
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import CourseMixin from "@/mixins/CourseMixin";
import AssetAudio from "@/components/course/lesson/assets/AssetAudio";
import AssetBingo from "@/components/course/lesson/assets/AssetBingo";
import AssetDocument from "@/components/course/lesson/assets/AssetDocument";

export default {
  name: "LessonAssets",
  components: { AssetAudio, AssetBingo, AssetDocument },
  mixins: [CourseMixin],
  computed: {
    assets() {
      return this.lesson.assets || [];
    },
  },
};
</script>
