<template>
  <asset-download :asset-data="assetData" asset-type="mdi-podcast" />
</template>

<script>
import AssetDownload from "@/components/course/lesson/assets/AssetDownload";

export default {
  name: "AssetAudio",
  components: { AssetDownload },
  props: {
    assetData: Object,
  },
};
</script>
